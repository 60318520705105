<template>
    <inertia-link :href="url('bewaard')" class="inline-flex items-center justify-center p-2 rounded-md hover:bg-gray-100 focus:outline-none relative">
        <span class="rounded-full text-white bg-dgreen text-xs px-2 py-0.5 z-10 font-bold absolute right-1 top-0 -m-2" v-if="$page.props.wishlist && $page.props.wishlist.count">{{
            $page.props.wishlist.count
        }}</span>

        <span class="sr-only">Bewaard</span>
        <fa :icon="['fal', 'heart']" class="text-xl h-5 opacity-80" />
    </inertia-link>

    <div class="inline-block">
        <button class="inline-flex items-center justify-center p-2 rounded-md hover:bg-gray-100 focus:outline-none relative" @click="cartOpened = true">
            <span
                class="rounded-full text-white bg-dgreen text-xs px-2 py-0.5 z-10 font-bold absolute right-1 top-0 -m-2"
                v-if="$page.props.cart.data && $page.props.cart.data.items.length"
                >{{ $page.props.cart.data.items.length }}</span
            >
            <fa :icon="['fal', 'shopping-cart']" class="text-xl h-5 w-5" />
        </button>
        <SlideCart v-if="cartOpened" />
    </div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';
import { defineAsyncComponent, onMounted, ref, onUnmounted ,computed} from 'vue';
const gtag = computed(() => usePage().props.flash.ga4tag);
const inBrowser = typeof window !== 'undefined';

onMounted(() => {
    if (inBrowser) {
        window.mitt.on('close-slideover', (evt) => {
            setTimeout(() => {
                cartOpened.value = false;
            }, 200);
        });
        window.mitt.on('openCartSlide', openCartSlide);
    }
});

onUnmounted(() => window.mitt.off('openCartSlide', openCartSlide));

function openCartSlide() {
    cartOpened.value = true;

    if (gtag) {
        let parsedGtag = JSON.parse(JSON.stringify(gtag.value));
        dataLayer.push({ ecommerce: null });
        dataLayer.push(parsedGtag);
        if (typeof hj !== 'undefined') {
            hj('event', 'AddToCart');
        }
    } else {
        if (typeof hj !== 'undefined') {
            hj('event', 'OpenCart');
        }
    }
}

const cartOpened = ref(false);

const SlideCart = defineAsyncComponent(() => import('@/Components/SlideCart.vue'));
</script>
