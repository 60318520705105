/**
 * @type {import('vanilla-cookieconsent').CookieConsentConfig}
 */

let mobile;

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    // true for mobile device
    mobile = true;
} else {
    mobile = false;
}
let firstConsent = false;
const config = {
    cookie: {
        expiresAfterDays: (acceptType) => {
            return acceptType === 'all' ? 365 : 182;
        },
    },

    guiOptions: {
        consentModal: {
            layout: 'box',
            // position: 'bottom left',
            position: 'middle center',
            equalWeightButtons: false,
            flipButtons: false,
        },
        preferencesModal: {
            layout: 'box',
            // position: 'right',
            position: 'middle center',
            equalWeightButtons: false,
            flipButtons: !mobile,
        },
    },

    revision: 2,

    onFirstConsent: (cookie) => {
        consent(cookie);
        logConsent();
        firstConsent = true;
    },

    onConsent: (cookie) => {
        if (typeof consent_defaults === 'undefined') {
            consent(cookie);
        } else if (consent_defaults == 'consent_not_set') {
            if (!firstConsent) {
                restoreConsent();
            }
        }
    },

    onModalShow: (modal) => {
        if (modal.modalName == 'preferencesModal') {
            if (typeof hj !== 'undefined') {
                hj('event', 'consent_show_preference_modal');
            }
            dataLayer.push({ event: 'consent_show_preference_modal' });
        }
    },

    onModalHide: (modal) => {},

    onChange: (cookie, changedCategories, changedServices) => {
        consent(cookie);
        logConsent(true);
    },

    categories: {
        necessary: {
            readOnly: true,
            enabled: true,
        },
        analytics: {
            autoClear: {
                cookies: [
                    {
                        name: /^(_ga|_gid)/,
                    },
                ],
            },
            enabled: true,
        },
        ads: {
            // readOnly: true,
            enabled: false,
        },
    },

    language: {
        default: 'nl',
        autoDetect: 'browser',
        translations: {
            // nl: url('translations/cookie.nl.json'),
            nl: {
                consentModal: {
                    title: '<img src="'+window.file_asset_url+'/img/cookie-bite-sharp-thin.svg" class="inline-block mr-2" height="20px" width="20px" /> <span>Cookies</span>',
                    description:
                        'Onze website werkt dankzij functionele cookies optimaal en we plaatsen analytische cookies om onze website elke dag een beetje beter te kunnen maken. Met persoonlijke cookies wordt het mogelijk om onze klanten persoonlijke en relevante content te laten zien. Wil je onze website optimaal kunnen gebruiken? Door op \'Ik begrijp het\' te klikken, accepteer jij onze cookies. Klik je op <button class="mr-1 text-blue-600" type="button" data-cc="accept-custom">weigeren</button>? Dan plaatsen wij alleen functionele en analytische cookies.</div>',
                    acceptAllBtn: 'Ik begrijp het',
                    showPreferencesBtn: 'Voorkeuren',
                    footer: '<a target="_blank" href="/privacy">Privacy</a><a target="_blank" href="/algemene-voorwaarden">Voorwaarden</a>',
                },
                preferencesModal: {
                    title: 'Voorkeuren',
                    acceptAllBtn: 'Accepteer alles',
                    acceptNecessaryBtn: 'Weiger alles',
                    closeIconLabel: 'Sluit',
                    savePreferencesBtn: 'Voorkeuren opslaan',
                    sections: [
                        {
                            title: 'Privacy voorkeuren cookies',
                            description:
                                'Geef hier jouw persoonlijke privacy voorkeuren aan. Onze website werkt dankzij functionele cookies optimaal en we plaatsen analytische cookies om onze website elke dag een beetje beter te kunnen maken. Met persoonlijke cookies wordt het mogelijk om onze klanten persoonlijke en relevante content te laten zien.',
                        },
                        {
                            title: 'Strikt noodzakelijke cookies',
                            description:
                                'Onmisbaar voor optimale functionaliteit bij Doorax gebruiken we wat we noemen "strikt noodzakelijke cookies" - die zijn als het onderhoudsmiddel voor je cilinderslot. Ze zorgen ervoor dat alles soepel verloopt als je onze website bezoekt. Denk aan het navigeren door pagina\'s en veilig inloggen. Geen zorgen, deze cookies verzamelen geen persoonlijke gegevens en worden automatisch geplaatst als je onze site bezoekt. Ze zijn nodig om alles te laten werken zoals het hoort.',
                            linkedCategory: 'necessary',
                        },
                        {
                            title: 'Prestatie- en analysecookies',
                            description:
                                'Voor een gebruiksvriendelijke shopervaring bij Doorax maken we ook gebruik van prestatie- en analysecookies - ze zijn net als ons team, altijd op zoek naar een betere klantervaring. Deze cookies helpen ons te begrijpen hoe bezoekers onze website gebruiken, zodat we deze kunnen verbeteren. Geen zorgen, jouw privacy staat voorop en we gebruiken deze gegevens alleen om onze website beter te maken voor jou en anderen. Dus terwijl jij geniet van onze gebruiksvriendelijke site, werken wij achter de schermen aan een nóg betere ervaring voor andere bezoekers!',
                            linkedCategory: 'analytics',
                            cookieTable: {
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Domain',
                                    desc: 'Description',
                                },
                                body: [
                                    {
                                        name: '_ga',
                                        domain: 'google.com',
                                        desc: 'Google Analytics',
                                    },
                                    {
                                        name: '_gid',
                                        domain: 'google.nl',
                                        desc: 'Google Analytics',
                                    },
                                    {
                                        name: '_hjSession_*',
                                        domain: 'hotjar.com',
                                        desc: 'Analytics',
                                    },
                                ],
                            },
                        },
                        {
                            title: 'Advertentie- en targetingcookies',
                            description:
                                'Op maat gemaakt voor jou bij Doorax gebruiken we ook advertentie- en targetingcookies - ze zijn net als onze cilindersloten, op maat gemaakt voor jou. Deze cookies helpen ons om advertenties te tonen die relevant zijn voor jouw interesses. Maak je geen zorgen, jouw privacy is belangrijk voor ons en we delen geen persoonlijke gegevens met adverteerders zonder jouw toestemming.',
                            linkedCategory: 'ads',
                            cookieTable: {
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Domain',
                                    desc: 'Description',
                                },
                                body: [
                                    {
                                        name: 'MUID',
                                        domain: '.bing.com',
                                        desc: 'Advertisement',
                                    },
                                    {
                                        name: 'ANONCHK',
                                        domain: '.c.clarity.ms',
                                        desc: 'Advertisement',
                                    },
                                ],
                            },
                        },
                    ],
                },
            },
            en: url('translations/cookie.en.json'),
        },
    },
    // disablePageInteraction: mobile,
    disablePageInteraction: true,
};

function consent(cookie) {
    let consentType;

    const preferences = $CookieConsent.getUserPreferences();

    if (preferences.acceptedCategories.length == 3) {
        consentType = 'consent_allow_all';
    } else if (preferences.acceptedCategories.length == 2) {
        if (preferences.acceptedCategories[1] == 'ads') {
            consentType = 'consent_only_ads';
        } else {
            consentType = 'consent_only_analytics';
        }
    } else if (preferences.acceptedCategories.length == 1) {
        consentType = 'consent_deny_all';
    }

    if (typeof hj !== 'undefined') {
        hj('event', consentType);
    }
    dataLayer.push({ event: consentType });
}

function restoreConsent() {
    // Retrieve all the fields
    const cookie = $CookieConsent.getCookie();
    const preferences = $CookieConsent.getUserPreferences();

    const userConsent = {
        consentId: cookie.consentId,
        acceptType: preferences.acceptType,
        acceptedCategories: preferences.acceptedCategories,
        rejectedCategories: preferences.rejectedCategories,
    };

    axios
        .post('/consent/restore', {
            body: JSON.stringify(userConsent),
        })
        .catch(function (error) {
            // Sentry.captureException(error);
        });
}

function logConsent(update = false) {
    // Retrieve all the fields
    const cookie = $CookieConsent.getCookie();
    const preferences = $CookieConsent.getUserPreferences();

    const userConsent = {
        consentId: cookie.consentId,
        acceptType: preferences.acceptType,
        acceptedCategories: preferences.acceptedCategories,
        rejectedCategories: preferences.rejectedCategories,
    };

    // Send the data to your backend
    axios
        .post('/consent/log', {
            body: JSON.stringify(userConsent),
        })
        .catch(function (error) {
            // Sentry.captureException(error);
        });
}

function url(value, param) {
    if (param) {
        return `${base_url}/${value}/${param}`;
    } else {
        return `${base_url}/${value}`;
    }
}

export default config;
