const inBrowser = typeof window !== 'undefined';

if (inBrowser) {
    document.addEventListener('DOMContentLoaded', function () {
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        // window.loaded = true
        // window.dispatchEvent(new Event('load'));

        window.addEventListener('vite:preloadError', (event) => {
            window.location.reload();
        });
    });
}
